.checklist table {
  width: 100%;
}

.checklist th,
.checklist td {
  border: 1px solid black;

}



/* Hide the element with the class "hide-on-print" when printing */
.hide-on-form {
  display: none;
}

/* Show the element with the class "show-on-print" when printing */
@media print {
  td {
    padding: "10px";
  }

  .hide-on-print {
    display: none;
  }
  .show-on-print {
    display: block; /* or inline-block, depending on your component */
  }
  button {
    display: none !important;
  }
  #formToPrint {
    font-size: 4pt; /* Adjust the font size as per your requirement */
  }
  .checklist thead {
    display: table-header-group;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 10px;
  height: 12px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
  border-radius: 10px;
}


h4,h6{
  font-family:"Calibri", sans-serif !important;
}

#singlebordertable > tr > th,
#singlebordertable > tr > td {
  border: none !important;
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;
}

#singlebordertable > tr > td:last-child {
  border-right: 1px solid black !important;
}

#singlebordertable > tr > th:last-child {
    border-right: 1px solid black !important;
  }

#singlebordertable > tr:last-child td {
  border-bottom: 1px solid black !important;
}

#singlebordertable > tr:nth-child(2) td.common-header {
  border-top: 0px solid black !important;
}

#singlebordertable > tr > td.common-border {
  border-bottom: 1px solid black !important;
}

#pagbreaksborder {
  border-top: 1px solid #0000 !important;
  border-bottom: 1px solid #0000 !important;
  page-break-inside:avoid !important;
}

